import React from 'react';

class MapSection extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                {/* map*/}
                <div id="map-canvas"></div>
            </div>
        );
    }
}

export default MapSection;
