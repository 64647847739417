import React from 'react';
import ThemeSwitcher from '../component/ThemeSwitcher';
import Preloader from '../component/Preloader';
import Navbar from '../component/Navbar';
import Slider from '../component/Slider';
import ServicesSection from '../component/ServicesSection';
import NewsletterSubscription from '../component/NewsletterSubscription';
import ClientReviews from '../component/ClientReviews';
import CalloutSection from '../component/CalloutSection';
import StatsSection from '../component/StatsSection';
import ContactSection from '../component/ContactSection';
import MapSection from '../component/MapSection';
import Footer from '../component/Footer';

class Home extends React.Component {
    render() {
        return (
            <div id="page-top">
                <ThemeSwitcher />
                <Preloader/>
                <Navbar/>
                <Slider/>
                <ServicesSection/>
                <NewsletterSubscription/>
                <ClientReviews/>
                <CalloutSection/>
                <StatsSection/>
                <ContactSection/>
                <MapSection/>
                <Footer/>
            </div>
        );
    }
}

export default Home;