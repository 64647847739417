import React from "react";

class ServicesSection extends React.Component {
  render() {
    return (
      <section
        id="services-index"
        className="bg-pattern"
        data-bottom-top="background-position: 0px 70%,99% 70;"
        data-top-bottom="background-position: 0px -50%,99% -50%;"
      >
        <div className="container">
          <div className="section-heading">
            <h2>Our Services</h2>
          </div>
          <div className="col-md-10 col-md-offset-1 text-center">
            <p>
              Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus
              odio, vestibulum in vulputate at, Nulla vel metus scelerisque ante
              sollicitudin commodo. Cras purus odio, vestibulum in vulputate at,
              tempus vi tempus viverra turpis. Fusce condimentum nunc ac nisi
              vulputate fringilla. Donec lacinia congue felis in faucibus.
            </p>
          </div>
        </div>
        <div className="container-fluid margin1">
          <div className="col-md-10 col-md-offset-1">
            <div id="owl-services" className="owl-carousel">
              <div className="col-xs-12">
                <div className="box_icon">
                  <div className="icon">
                    <div className="image">
                      <img
                        src="/img/service1.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <h4>Exotic Pets</h4>
                      <p>
                        Nulla vel metus scelerisque ante sollicitudinlorem
                        ipsuet commodo. Cras purus odio, vestibulum in vulputate
                        a Imperdiet interdum donec eget metus auguen unc vel
                        lorem.
                      </p>
                      <a className="btn" href="services-single.html">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="box_icon">
                  <div className="icon">
                    <div className="image">
                      <img
                        src="/img/service2.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <h4>Vet Services</h4>
                      <p>
                        Nulla vel metus scelerisque ante sollicitudinlorem
                        ipsuet commodo. Cras purus odio, vestibulum in vulputate
                        a Imperdiet interdum donec eget metus auguen unc vel
                        lorem.
                      </p>
                      <a className="btn" href="services-single.html">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="box_icon">
                  <div className="icon">
                    <div className="image">
                      <img
                        src="/img/service3.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <h4>Great Products</h4>
                      <p>
                        Nulla vel metus scelerisque ante sollicitudinlorem
                        ipsuet commodo. Cras purus odio, vestibulum in vulputate
                        a Imperdiet interdum donec eget metus auguen unc vel
                        lorem.
                      </p>
                      <a className="btn" href="services-single.html">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="box_icon">
                  <div className="icon">
                    <div className="image">
                      <img
                        src="/img/service4.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <h4>Pet Hotel</h4>
                      <p>
                        Nulla vel metus scelerisque ante sollicitudinlorem
                        ipsuet commodo. Cras purus odio, vestibulum in vulputate
                        a Imperdiet interdum donec eget metus auguen unc vel
                        lorem.
                      </p>
                      <a className="btn" href="services-single.html">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12">
                <div className="box_icon">
                  <div className="icon">
                    <div className="image">
                      <img
                        src="/img/service5.jpg"
                        className="img-responsive"
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <h4>Dog Walking</h4>
                      <p>
                        Nulla vel metus scelerisque ante sollicitudinlorem
                        ipsuet commodo. Cras purus odio, vestibulum in vulputate
                        a Imperdiet interdum donec eget metus auguen unc vel
                        lorem.
                      </p>
                      <a className="btn" href="services-single.html">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section id="about-index" className="bg-lightcolor1">
          <div className="container">
            <div className="section-heading text-center">
              <h2>About Us</h2>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-7 text-center">
                <h3>For your best friend</h3>
                <p>
                  Pat a lorem ipsuet nibh fusce mollis imperdie tlorem ipuset
                  phas ellus ac sodales Lorem ipsum dolor Phas ellus ac sodales
                  felis tiam non metus. vest ibulum orci eget usce mollis
                  imperdiet interdum donec eget metus auguen unc vel lorem
                  ispuet Ibu lum orci eget, viverra elit liquam erat volut pat
                  phas ellus ac sodales Lorem ipsum dolor sit amet, consectetur
                  adipisicing elit uasi quidem minus id iprum omnis.
                </p>
                <div className="panel-group" id="accordion">
                  <div className="panel">
                    <div className="panel-heading">
                      <h5 className="panel-title">
                        <a
                          className="accordion-toggle"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseOne"
                        >
                          Social Responsability
                        </a>
                      </h5>
                    </div>
                    <div
                      id="collapseOne"
                      className="panel-collapse collapse in"
                    >
                      <div className="panel-body">
                        <p>
                          Patatemp dolupta orem retibusam qui commolu les felis
                          tiam non metus ali quam eros Pellentesque turpis
                          lectus, placerat a ultricies a, posuere a nibh. Fusce
                          mollis imperdiet interdum donec eget metus auguen unc
                          vel mauris ultricies, vest ibulum orci eget, viverra
                          elit.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel">
                    <div className="panel-heading">
                      <h5 className="panel-title">
                        <a
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseTwo"
                        >
                          Mission Statement
                        </a>
                      </h5>
                    </div>
                    <div id="collapseTwo" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          Patatemp dolupta orem retibusam qui commolu les felis
                          tiam non metus ali quam eros Pellentesque turpis
                          lectus, placerat a ultricies a, posuere a nibh. Fusce
                          mollis imperdiet interdum donec eget metus auguen unc
                          vel mauris ultricies, vest ibulum orci eget, viverra
                          elit.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="panel">
                    <div className="panel-heading">
                      <h5 className="panel-title">
                        <a
                          className="accordion-toggle collapsed"
                          data-toggle="collapse"
                          data-parent="#accordion"
                          href="#collapseThree"
                        >
                          Value Added Services
                        </a>
                      </h5>
                    </div>
                    <div id="collapseThree" className="panel-collapse collapse">
                      <div className="panel-body">
                        <p>
                          Patatemp dolupta orem retibusam qui commolu les felis
                          tiam non metus ali quam eros Pellentesque turpis
                          lectus, placerat a ultricies a, posuere a nibh. Fusce
                          mollis imperdiet interdum donec eget metus auguen unc
                          vel mauris ultricies, vest ibulum orci eget, viverra
                          elit.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="parallax-object1 hidden-sm hidden-xs"
                data-100-start="right: 0%;"
                data-center-bottom="right:30%;"
              >
                <img src="img/illustrations/petbowl.png" alt="" />
              </div>
              <div className="col-md-6 col-lg-5">
                <img
                  src="img/about-index.png"
                  className="img-responsive"
                  alt=""
                />
              </div>
            </div>
            <div className="row margin1 text-center">
              <div className="features col-lg-3 col-sm-6 col-xs-12">
                <div className="col-xs-12 big-icon">
                  <i className="flaticon-dog-and-pets-house"></i>
                </div>
                <div className="col-xs-12">
                  <h5>Housing</h5>
                  <p>
                    Aenean lacinia bibendum nulla sed consectetur ras mattis
                    consectetur purus sit amet fermentum sociis natoque
                    penatibus et magnis.
                  </p>
                </div>
              </div>
              <div className="features col-lg-3 col-sm-6 col-xs-12">
                <div className="col-xs-12 big-icon">
                  <i className="flaticon-dog-in-front-of-a-man"></i>
                </div>
                <div className="col-xs-12">
                  <h5>High Quality</h5>
                  <p>
                    Aenean lacinia bibendum nulla sed consectetur ras mattis
                    consectetur purus sit amet fermentum sociis natoque
                    penatibus et magnis.
                  </p>
                </div>
              </div>
              <div className="features col-lg-3 col-sm-6 col-xs-12">
                <div className="col-xs-12 big-icon">
                  <i className="flaticon-veterinarian-hospital"></i>
                </div>
                <div className="col-xs-12">
                  <h5>Vet Services</h5>
                  <p>
                    Aenean lacinia bibendum nulla sed consectetur ras mattis
                    consectetur purus sit amet fermentum sociis natoque
                    penatibus et magnis.
                  </p>
                </div>
              </div>
              <div className="features col-lg-3 col-sm-6 col-xs-12">
                <div className="col-xs-12 big-icon">
                  <i className="flaticon-animals-3"></i>
                </div>
                <div className="col-xs-12">
                  <h5>Special Care</h5>
                  <p>
                    Aenean lacinia bibendum nulla sed consectetur ras mattis
                    consectetur purus sit amet fermentum sociis natoque
                    penatibus et magnis.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default ServicesSection;
