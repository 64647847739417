import React from 'react';

class Navbar extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-custom navbar-fixed-top">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#navbar-brand-centered">
                            <i className="fa fa-bars"></i>
                        </button>
                        <div className="navbar-brand navbar-brand-centered page-scroll">
                            <a href="#page-top">
                                <img src="img/logo.png" className="img-responsive" alt="" />
                            </a>
                        </div>
                    </div>
                    <div className="collapse navbar-collapse" id="navbar-brand-centered">
                        <ul className="nav navbar-nav">
                            <li className="active"><a href="index.html">Home</a></li>
                            <li><a href="about.html">About</a></li>
                            <li className="dropdown">
                                <a className="dropdown-toggle" data-toggle="dropdown" href="#">Pages<b className="caret"></b></a>
                                <ul className="dropdown-menu">
                                    <li><a href="adoption.html">Adoption</a></li>
                                    <li><a href="adopt-single.html">Adoption Single</a></li>
                                    <li><a href="services.html">Our Services</a></li>
                                    <li><a href="services-single.html">Services Single</a></li>
                                    <li><a href="team.html">Our Team</a></li>
                                    <li><a href="pricing.html">Pricing</a></li>
                                    <li><a href="testimonials.html">Testimonials</a></li>
                                    <li><a href="elements.html">Elements</a></li>
                                </ul>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li><a href="gallery.html">Gallery</a></li>
                            <li className="dropdown">
                                <a className="dropdown-toggle" data-toggle="dropdown" href="#">Blog<b className="caret"></b></a>
                                <ul className="dropdown-menu">
                                    <li><a href="blog.html">Blog Home</a></li>
                                    <li><a href="blog-single.html">Blog Post</a></li>
                                </ul>
                            </li>
                            <li><a href="contact.html">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Navbar;
