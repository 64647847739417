import React from 'react';

class ClientReviews extends React.Component {
    render() {
        return (
            <section id="reviews">
                <div className="container">
                    <div className="section-heading text-center">
                        <h2>Clients Reviews</h2>
                    </div>
                    <div className="parallax-object2 hidden-sm hidden-xs hidden-md" data-100-start="transform:rotate(-0deg); left:20%;" data-top-bottom="transform:rotate(-370deg);">
                        <img src="img/illustrations/toy.png" alt="" />
                    </div>
                    <div className="col-md-9 col-md-offset-3">
                        <div id="owl-reviews" className="owl-carousel margin1">
                            <div className="review">
                                <div className="col-xs-12">
                                    <div className="review-caption">
                                        <h5>Sue Shei</h5>
                                        <div className="small-heading">
                                            Dog Lover
                                        </div>
                                        <blockquote>
                                            Patatemp dolupta orem retibusam qui commolu Fusce mollis imperdiet interdum donec eget metus auguen unc vel mauris ultricies, vest ibulum orci eget, viverra.
                                        </blockquote>
                                    </div>
                                    <div className="review-profile-image">
                                        <img src="img/review1.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="review">
                                <div className="col-xs-12">
                                    <div className="review-caption">
                                        <h5>Jonas Smith</h5>
                                        <div className="small-heading">
                                            Cat Specialist
                                        </div>
                                        <blockquote>
                                            Patatemp dolupta orem retibusam qui commolu Fusce mollis imperdiet interdum donec eget metus auguen unc vel mauris ultricies, vest ibulum orci eget, viverra.
                                        </blockquote>
                                    </div>
                                    <div className="review-profile-image">
                                        <img src="img/review2.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="review">
                                <div className="col-xs-12">
                                    <div className="review-caption">
                                        <h5>Maria Silva</h5>
                                        <div className="small-heading">
                                            Exotic Birds Vet
                                        </div>
                                        <blockquote>
                                            Patatemp dolupta orem retibusam qui commolu Fusce mollis imperdiet interdum donec eget metus auguen unc vel mauris ultricies, vest ibulum orci eget, viverra.
                                        </blockquote>
                                    </div>
                                    <div className="review-profile-image">
                                        <img src="img/review3.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="review">
                                <div className="col-xs-12">
                                    <div className="review-caption">
                                        <h5>Lou Lou</h5>
                                        <div className="small-heading">
                                            Veterinarian
                                        </div>
                                        <blockquote>
                                            Patatemp dolupta orem retibusam qui commolu Fusce mollis imperdiet interdum donec eget metus auguen unc vel mauris ultricies, vest ibulum orci eget, viverra.
                                        </blockquote>
                                    </div>
                                    <div className="review-profile-image">
                                        <img src="img/review4.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="review">
                                <div className="col-xs-12">
                                    <div className="review-caption">
                                        <h5>James Doe</h5>
                                        <div className="small-heading">
                                            Pet Lover
                                        </div>
                                        <blockquote>
                                            Patatemp dolupta orem retibusam qui commolu Fusce mollis imperdiet interdum donec eget metus auguen unc vel mauris ultricies, vest ibulum orci eget, viverra.
                                        </blockquote>
                                    </div>
                                    <div className="review-profile-image">
                                        <img src="img/review5.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ClientReviews;
