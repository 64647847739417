import React from 'react';

class Slider extends React.Component {
    render() {
        return (
            <div className="slider-container">
                {/* Controls */}
                <div className="slider-control left inactive"></div>
                <div className="slider-control right"></div>
                <ul className="slider-pagi"></ul>
                {/* Slider */}
                <div className="slider">
                    {/* Slide 0 */}
                    <div className="slide slide-0 active" style={{backgroundImage: "url('img/slide0.jpg')"}}>
                        <div className="slide__bg"></div>
                        <div className="slide__content">
                            <div className="slide__overlay"></div>
                            {/* Slide text */}
                            <div className="slide__text">
                                <h1 className="slide__text-heading">Welcome to PETZ</h1>
                                <div className="hidden-mobile">
                                    <p className="lead">Petz is a fun HTML5 Pet Template with many elements for your Pet related website</p>
                                    <a href="services.html" className="btn btn-default">Our services</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Slide 1 */}
                    <div className="slide slide-1" style={{backgroundImage: "url('img/slide1.jpg')"}}>
                        <div className="slide__bg"></div>
                        <div className="slide__content">
                            <div className="slide__overlay"></div>
                            {/* Slide text */}
                            <div className="slide__text">
                                <h1 className="slide__text-heading">We Love Pets!</h1>
                                <div className="hidden-mobile">
                                    <p className="lead">We offer all the best quality products for your best friend.</p>
                                    <a href="blog.html" className="btn btn-default">Our Blog</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Slide 2 */}
                    <div className="slide slide-2" style={{backgroundImage: "url('img/slide2.jpg')"}}>
                        <div className="slide__bg"></div>
                        <div className="slide__content">
                            <div className="slide__overlay"></div>
                            {/* Slide text */}
                            <div className="slide__text">
                                <h1 className="slide__text-heading">Amazing Services</h1>
                                <div className="hidden-mobile">
                                    <p className="lead">We offer all the best quality products for your best friend.</p>
                                    <a href="about.html" className="btn btn-default">About Us</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Slide 2 */}
                </div>
                {/* Slider */}
            </div>
        );
    }
}

export default Slider;
