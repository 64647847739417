import React from 'react';

class ContactSection extends React.Component {
    render() {
        return (
            <section id="contact-index">
                <div className="container">
                    <div className="section-heading">
                        <h2>Contact Us</h2>
                    </div>
                    <div className="col-lg-7 col-lg-offset-5 col-md-8 col-md-offset-2">
                        <h4>Information </h4>
                        <ul className="list-inline">
                            <li><i className="fa fa-envelope"></i><a href="mailto:youremailaddress">youremail@site.com</a></li>
                            <li><i className="fa fa-phone margin-icon"></i>Call Us +1 456 7890</li>
                            <li><i className="fa fa-map-marker margin-icon"></i>Lorem Ipsum 505</li>
                        </ul>
                        <p>Elit uasi quidem minus id omnis a nibh fusce mollis imperdie tlorem ipuset phas ellus ac sodales Lorem ipsum dolor Phas ellus
                        </p>
                        <h4 className="margin1">Send us a Message</h4>
                        <div id="contact_form">
                            <div className="form-group">
                                <label>Name<span className="required">*</span></label>
                                <input type="text" name="name" className="form-control input-field" required="" />
                                <label>Email Adress <span className="required">*</span></label>
                                <input type="email" name="email" className="form-control input-field" required="" />
                                <label>Subject</label>
                                <input type="text" name="subject" className="form-control input-field" required="" />
                                <label>Message<span className="required">*</span></label>
                                <textarea name="message" id="message" className="textarea-field form-control" rows="3" required=""></textarea>
                            </div>
                            <button type="submit" id="submit_btn" value="Submit" className="btn center-block">Send message</button>
                        </div>
                        <div id="contact_results"></div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactSection;