import React from 'react';

class NewsletterSubscription extends React.Component {
    render() {
        return (
            <div className="container-fluid">
                <section className="call-to-action small-section bg1" data-stellar-background-ratio="0.2">
                    <div className="container text-center">
                        <div className="col-md-5 bg-darkcolor well">
                            <div className="col-md-12">
                                <h4>Subscribe to our newsletter</h4>
                                <p>Ultricies a,interdum donec eget metus auguen u Fusce mollis imperdiet interdum donec eget metus auguen unc vel lorem.</p>
                                <div id="mc_embed_signup" className="margin1">
                                    <form action="//yourlist.us12.list-manage.com/subscribe/post?u=04e646927a196552aaee78a7b&id=111" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                        <div id="mc_embed_signup_scroll">
                                            <div className="mc-field-group">
                                                <div className="input-group">
                                                    <input className="form-control input-lg required email" type="email" value="" name="EMAIL" placeholder="Your email here" id="mce-EMAIL" />
                                                    <span className="input-group-btn">
                                                        <button className="btn" type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe">Subscribe</button>
                                                    </span>
                                                </div>
                                                <div id="mce-responses" className="mailchimp">
                                                    <div className="alert alert-danger response" id="mce-error-response"></div>
                                                    <div className="alert alert-success response" id="mce-success-response"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

export default NewsletterSubscription;
