import React from 'react';

class StatsSection extends React.Component {
    render() {
        return (
            <section id="stats" className="bg-lightcolor2">
                <div className="section-heading text-center">
                    <h2>Our Stats</h2>
                </div>
                <div className="container">
                    <div className="col-lg-9 col-md-8 col-sm-8">
                        <div className="text-center">
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="numscroller" data-slno='1' data-min='0' data-max='180' data-delay='20' data-increment="19">0</div>
                                <h5>Customers</h5>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="numscroller" data-slno='1' data-min='0' data-max='16' data-delay='10' data-increment="9">0</div>
                                <h5>Professionals</h5>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="numscroller" data-slno='1' data-min='0' data-max='67' data-delay='20' data-increment="19">0</div>
                                <h5>Pets Hosted</h5>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6">
                                <div className="numscroller" data-slno='1' data-min='0' data-max='50' data-delay='10' data-increment="9">0</div>
                                <h5>Products</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}
export default StatsSection;
