import React from 'react';

class CalloutSection extends React.Component {
    render() {
        return (
            <section className="callout container-fluid no-padding">
                <div className="row-fluid">
                    <div className="col-lg-8 col-md-12 no-padding" data-start="right: 20%;" data-center="right:0%;">
                        <img src="img/call1.jpg" className="img-responsive img-rounded" alt="" />
                    </div>
                    <div className="callout-box col-lg-6 col-lg-offset-6 bg-darkcolor" data-start="left: 20%;" data-center="left:0%;">
                        <h3>We Love Pets</h3>
                        <p>Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, Nulla vel metus scelerisque ante sollicitudin commodo. Cras purus odio, vestibulum in vulputate at, tempus vi tempus viverra turpis. Fusce
                            condimentum nunc ac nisi vulputate fringilla. Donec lacinia congue felis in faucibus.</p>
                        <a href="contact.html" className="btn">Contact us</a>
                    </div>
                </div>
            </section>
        );
    }
}

export default CalloutSection;
