import React from 'react';

class ThemeSwitcher extends React.Component {
    render() {
        return (
            <div className="demo_changer">
                <div className="demo-icon">
                    <i className="fa fa-cog fa-spin fa-2x"></i>
                </div>
                <div className="form_holder text-center">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="predefined_styles">
                                <h5>Choose a Color Skin</h5>
                                <a href="bluecollarpet" className="styleswitch"><img src="switcher/images/bluecollarpet.png" alt="Blue Collar Pet" /></a>
                                <a href="delicatepet" className="styleswitch"><img src="switcher/images/delicate.png" alt="Delicate Pet" /></a>
                                <a href="tropicalpet" className="styleswitch"><img src="switcher/images/tropical.png" alt="Tropical Pet" /></a>
                                <a href="sweetpet" className="styleswitch"><img src="switcher/images/sweet.png" alt="Sweet Pet" /></a>
                                <a href="maincolors" className="styleswitch"><img src="switcher/images/maincolors.png" alt="Main Colors" /></a>
                            </div>
                            <h5>Change Home Header</h5>
                            <select id="headers" className="show-menu-arrow selectpicker" onChange={(e) => {document.location = e.target.value}}>
                                <option value="">Select</option>
                                <option value="index1.html">Video Header</option>
                                <option value="index.html">Drag Slider</option>
                            </select>
                            <h5>Layout</h5>
                            <select id="headers2" className="show-menu-arrow selectpicker" onChange={(e) => {document.location = e.target.value}}>
                                <option value="">Select</option>
                                <option value="index-boxed.html">Boxed</option>
                                <option value="index.html">Fullwidth</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ThemeSwitcher;
